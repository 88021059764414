










































import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseDialog extends Vue {
    @ModelSync("shownValue", "change", { type: Boolean })
    shown!: boolean;

    @Prop({ type: String, required: false, default: "" })
    title!: string;

    @Prop({ type: String, required: false, default: "" })
    subtitle!: string;

    @Prop({ type: Boolean, required: false, default: false })
    loading!: boolean;

    handleInput(activated: boolean) {
        if (!activated) this.$emit("on-close");
    }
}
